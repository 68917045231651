import React from "react";

import { MenuLevelType } from "../../../queries/menu";
import { ShopProductCategory } from "../../../queries/shop";
import { ColumnMenuLevel } from "./ColumnMenuLevel/ColumnMenuLevel";

interface Props {
  link: string;
  depth: number;
  type: MenuLevelType;
  item: ShopProductCategory;
}

export const MenuLevel = (props: Props) => {
  if (!props.item.children) {
    return <></>;
  }

  return (
    <ColumnMenuLevel
      link={props.link}
      id={props.item.id}
      title={props.item.name}
      depth={props.depth + 1}
      items={props.item.children.nodes}
    />
  );
};

import React, { useCallback, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Flickity from "react-flickity-component";
import { ApolloError } from "apollo-boost";
import { Container } from "../Container/Container";
import { Product } from "../ListOfProducts/Product";
import { ProductProps } from "../../types/productTypes";
import SlideShowLoader from "./SlideShowLoader";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

import "flickity/dist/flickity.css";
import "./SlideShow.scss";

interface SlideShowProps {
  items: any;
  title: string;
  loading: boolean;
  error: ApolloError | undefined;
  paddingLeft?: number;
  redirectLink?: string;
}

export const SlideShow = (props: SlideShowProps) => {
  const flickity = useRef<Flickity | null>(null);
  const history = useHistory();
  const [titleRef, setTitleRef] = useState<any>(null);
  const windowWidth = useWindowWidth();
  const slideTitle = String(props.title).charAt(0).toUpperCase() + String(props.title).slice(1);
  const isTablet = windowWidth < 1024;

  const getTitleRef = useCallback((c) => {
    if (c !== null) {
      setTitleRef(c);
    }
  }, []);

  const mobileItems = props.items
    ?.map((item: any, i: number) => {
      return i % 2 === 0 && props.items?.slice(i, i + 2);
    })
    ?.filter((e: any) => e);

  if (props.loading) {
    return (
      <div className="SlideShow">
        <div className="SlideShow__wrapper">
          <SlideShowLoader />
        </div>
      </div>
    );
  }

  if (props.error) {
    return null;
  }

  return (
    <div className="SlideShow">
      <Container className="p-0-mobile d-flex justify-content-between">
        <h2 ref={getTitleRef} className="font-weight-bold">
          {slideTitle}
        </h2>
        <div className="SlideShow__title d-flex">
          <span onClick={() => history.push(`/shop?${props.redirectLink}`)}>
            Shiko të gjitha
          </span>
        </div>
      </Container>
      <div className="SlideShow__wrapper">
        {!isTablet ? (
          <Flickity
            flickityRef={(c) => (flickity.current = c)}
            className={"carousel"}
            elementType={"div"}
            options={{
              wrapAround: false,
              draggable: true,
              cellAlign: props.items?.length <= 4 ? "center" : "left",
              freeScroll: true,
              resize: true,
              pageDots: !isTablet,
              prevNextButtons: false,
              contain: true,
              bgLazyLoad: true,
              lazyLoad: 1,
            }}
            disableImagesLoaded={false}
          >
            {!isTablet && (
              <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
            )}
            {props.items?.map((product: ProductProps, index: number) => {
              return (
                <div
                  key={`${product.id}-${index}`}
                  className="SlideShow-box"
                  id={`SlideShow-${product.slug}`}
                >
                  <Product product={product} key={`${product.id}-${index}`} />
                </div>
              );
            })}
            {!isTablet && (
              <div style={{ width: (titleRef?.offsetLeft || 0) - 13 }} />
            )}
          </Flickity>
        ) : (
          <div className="HomePageSlideShow--mobile">
            {mobileItems?.map((mobileItem: any, index: number) => {
              return (
                <div
                  key={index}
                  className="HomeSlider__slide d-flex flex-column"
                >
                  {mobileItem.map((item: any) => {
                    return (
                      <div
                        key={`${item.id}-${index}`}
                        className="HomeSlider__item"
                        id={`SlideShow-${item.slug}`}
                      >
                        <Product product={item} key={`${item.id}-${index}`} />
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

import { useMutation } from "@apollo/react-hooks";
import React from "react";
import Rate from "rc-rate";
import {
  Button,
  Col,
  FormGroup,
  Modal,
  ModalBody,
  Spinner,
  Container,
  Row,
  FormFeedback,
  Input,
  Form,
  Label,
} from "reactstrap";
import { useNotification } from "../../lib/context/NotificationContext/NotificationContext";
import { ORDER_FEEDBACK } from "../../queries/mutations/generalMutation";
import {
  errors,
  formMessages,
  formValidation,
} from "../../lib/translation/strings";
import { useTranslation } from "react-i18next";
import { useOrderFeedbackFormik } from "../../lib/formik/useOrderFeedback";
import { useRouter } from "../../lib/hooks/useRouter";

import "rc-rate/assets/index.css";
import "./OrderFeedbackModal.scss";

interface Props {
  orderId?: number;
  open: boolean;
  toggle: () => void;
}

export const OrderFeedbackModal = (props: Props) => {
  const router = useRouter();
  const { t } = useTranslation();
  const ntfCtx = useNotification();
  const { open, toggle, orderId } = props;

  const [mutation, { loading }] = useMutation(ORDER_FEEDBACK);

  const formik = useOrderFeedbackFormik({
    onSubmit: async (values) => {
      try {
        const res = await mutation({
          variables: {
            message: values.message,
            review: values.review,
          },
        });

        if (!res) {
          return;
        }
        props.toggle();
        ntfCtx.success(t(formMessages.thanksForComment), true);
        router.history.push(`/my-profile/orders/${orderId}`);
      } catch (error) {
        ntfCtx.danger(t(errors.wentWrong), true);
      }
    },
  });

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalBody>
        <Container>
          <div className="OrderFeedbackModal">
            <h1>Vlersoni porosinë me një koment</h1>
            <Form style={{ marginBottom: 30 }} onSubmit={formik.handleSubmit}>
              <Row>
                <Col xs="12" sm="12">
                  <div className="OrderFeedbackModal__rate">
                    <Label>Vlersimi nga 1 deri në 5</Label>
                    <Rate
                      style={{ fontSize: 30 }}
                      allowHalf={false}
                      onChange={(value: number) =>
                        formik.setFieldValue("review", value)
                      }
                    />
                    {formik.values.review === 0 && formik.touched.review && (
                      <span className="OrderFeedbackModal__rate_error">
                        {t(formValidation.required)}
                      </span>
                    )}
                  </div>
                </Col>
                <Col xs="12" sm="12">
                  <FormGroup className="form-group">
                    <Label for="message">Pëshkrimi</Label>
                    <Input
                      type="textarea"
                      id="message"
                      placeholder="Pëshkrimi"
                      className="form-control"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                    />
                    <FormFeedback>{formik.errors.message}</FormFeedback>
                  </FormGroup>
                </Col>
                <div className="w-100" />
                <Col xs="12">
                  <FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={loading || formik.values.review === 0}
                      className="w-100-mobile OrderFeedbackModal__button"
                    >
                      {loading ? <Spinner size="sm" /> : "Komento"}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            <div onClick={props.toggle} className="OrderFeedbackModal__close">
              <span>X</span>
            </div>
          </div>
        </Container>
      </ModalBody>
    </Modal>
  );
};

import React, { useState } from "react";
import cs from "classnames";
import { RouteComponentProps } from "react-router-dom";
import { useQuery } from "react-apollo";
import { useSearchParams } from "../../lib/hooks/useSearchParams";
import { ListOfProducts } from "../../components/ListOfProducts/ListOfProducts";
import { ApolloErrorGuard } from "../../components/shared/ApolloErrorGuard/ApolloErrorGuard";
import { HandleLoadingState } from "../../components/shared/HandleLoadingState/HandleLoadingState";
import { Layout } from "../../components/Shop/ui/Layout";
import { ShopContextProvider } from "../../lib/context/ShopContext/ShopContextProvider";
import { useShopHandlers } from "../../lib/hooks/useShopHandlers";
import { useOrderFieldsFromSlug } from "../../lib/hooks/useOrderFieldsFromSlug";
import { ShopSidebar } from "../../components/Shop/ShopSidebar/ShopSidebar";
import { Image } from "../../components/shared/Image/Image";
import { ShopLogoPlaceholder } from "../../components/Shop/ui/ShopLogoPlaceholder";
import * as shopQueries from "../../queries/shop";
import { BreadCrumb } from "../../components/BreadCrumb/BreadCrumb";
import { Container } from "../../components/Container/Container";
import { Button } from "../../components/shared/Button/Button";
import { useMenuSidebar } from "../../lib/context/MenuSidebarContext/MenuSidebarContext";
import * as brandQueries from "../../queries/brands";
import { ShopByCategories } from "../../components/ShopByCategories/ShopByCategories";
import { ShopBySubCategories } from "../../components/ShopBySubCategories/ShopBySubCategories";
import { ShopBySubSubCategories } from "../../components/ShopBySubSubCategories/ShopBySubSubCategories";

import "./ShopByBrand.scss";
import { ShopBySubSubSubCategories } from "../../components/ShopBySubSubSubCategories/ShopBySubSubSubCategories";
import { Spinner } from "reactstrap";
import { useShopFilterData } from "../../lib/hooks/useShopFilterData";
import { useWindowWidth } from "../../lib/hooks/useWindowWidth";

const postsLimit = 15;
const defaultField = "DATE";
const defaultOrder = "ASC";

interface Props {
  brandSlug: string;
  parentCategory?: string;
  childCategory?: string;
  childChildCategory?: string;
  childChildChildCategory?: string;
}

export const ShopByBrand = (props: RouteComponentProps<Props>) => {
  const searchParams = useSearchParams();
  const shopHandlers = useShopHandlers();
  const shopFilterData = useShopFilterData();
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= 1024;

  const menuSidebar = useMenuSidebar();
  const orderFields = useOrderFieldsFromSlug();
  const { data: dataNewProducts } = useQuery(shopQueries.NEW_PRODUCTS);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  // eslint-disable-next-line
  const [priceState, setPriceState] = useState({ min: 0, max: 1500 });

  const date = dataNewProducts?.themeOptions?.themeOptions?.newProducts?.split(
    "/"
  );
  const {
    childCategory,
    parentCategory,
    brandSlug,
    childChildCategory,
    childChildChildCategory,
  } = props.match.params;

  const brand = searchParams["brand"];

  const {
    data: brandData,
    loading: brandLoading,
    error: brandError,
  } = useQuery<
    brandQueries.GetBrandBySlugResponse,
    brandQueries.GetBrandBySlugVariables
  >(brandQueries.getBrandBySlug, {
    variables: {
      slug: brandSlug,
    },
  });

  const { loading, data, error, fetchMore, refetch } = useQuery<
    brandQueries.GetBrandProductsResponse,
    brandQueries.GetBrandProductsVariables
  >(brandQueries.getBrandProducts, {
    variables: {
      after: "",
      slug: props.match.params.brandSlug,
      category:
        childChildChildCategory ||
        childChildCategory ||
        childCategory ||
        parentCategory ||
        "",
      limit: postsLimit,
      field: orderFields.field,
      order: orderFields.order,
      taxonomyFilter: shopHandlers.prepareTaxonomyFilters(searchParams),
      day: date ? parseInt(date[0]) : undefined,
      month: date ? parseInt(date[1]) : undefined,
      year: date ? parseInt(date[2]) : undefined,
    },
  });

  const handleLoadMore = () => {
    setIsLoadingMore(true);
    fetchMore({
      variables: {
        slug: props.match.params.brandSlug,
        limit: postsLimit,
        after: data?.products?.pageInfo?.endCursor,
      },
      updateQuery: (previousResult: any, { fetchMoreResult }: any) => {
        const newNodes = fetchMoreResult.products.nodes;
        const pageInfo = fetchMoreResult.products.pageInfo;

        setIsLoadingMore(false);

        if (!newNodes.length) {
          return previousResult;
        }

        return {
          products: {
            __typename: previousResult.products.__typename,
            nodes: [...previousResult.products.nodes, ...newNodes],
            pageInfo,
          },
        };
      },
    });
  };

  const handlePriceChange = (value: number[]) => {
    setPriceState({
      min: value[0],
      max: value[1],
    });
  };

  const handlePriceAfterChange = (value: number[]) => {
    refetch({
      slug: props.match.params.brandSlug,
      limit: postsLimit,
      minPrice: value[0],
      maxPrice: value[1],
    });
  };

  const handleOrderChange = (value: string) => {
    shopHandlers.updateSingleQueryParameter("order", value, (field, order) => {
      refetch({
        slug: props.match.params.brandSlug,
        limit: postsLimit,
        field: field || defaultField,
        order: order || defaultOrder,
      });
    });
  };

  const handleAttributeChange = (data: any) => {
    const taxonomyFilters = shopHandlers.prepareTaxonomyFilters(data);
    refetch({
      slug: props.match.params.brandSlug,
      limit: postsLimit,
      taxonomyFilter: taxonomyFilters,
    });
  };

  const handleCategoryChange = (categorySlug: string) => {
    refetch({
      slug: props.match.params.brandSlug,
      limit: postsLimit,
      category: categorySlug,
      taxonomyFilter: [],
    });
  };

  const hasNextPage = !!data?.products?.pageInfo?.hasNextPage;
  const products = data?.products?.nodes;
  const noProductsAvailable = !products || Number(products.length) === 0;

  const brandName = brandData?.productBrands?.nodes[0]?.name ?? "";
  const brandLogo =
    brandData?.productBrands?.nodes[0]?.brandData?.logo?.sourceUrl;

  const path = [
    { name: brandName?.replaceAll("-", " "), slug: `${brandSlug}` },
    {
      name: parentCategory?.replaceAll("-", " "),
      slug: `${brandSlug}/${parentCategory}`,
    },
    {
      name: childCategory?.replaceAll("-", " "),
      slug: `${brandSlug}/${parentCategory}/${childCategory}`,
    },
    {
      name: childChildCategory?.replaceAll("-", " "),
      slug: `${brandSlug}/${parentCategory}/${childCategory}/${childChildCategory}`,
    },
    {
      name: childChildChildCategory?.replaceAll("-", " "),
      slug: `${brandSlug}/${parentCategory}/${childCategory}/${childChildCategory}/${childChildChildCategory}`,
    },
  ];

  return (
    <ShopContextProvider
      data={{
        data: {
          shopType: "brand",
          shopLinkPrefix: `brands/${props.match.params.brandSlug}`,
          slug: props.match.params.brandSlug,
          category: parentCategory,
          subCategory: childCategory,
          subSubCategory: childChildCategory,
          order: `${orderFields.field}-${orderFields.order}`,
        },
        handlers: {
          handleCategoryChange,
          handleAttributeChange,
          handleOrderChange,
        },
      }}
    >
      {loading ? (
        <div className="ShopByCategory__banner-loading">
          <Spinner size="lg" color="#000" />
        </div>
      ) : (
        <>
          {(parentCategory || childCategory) &&
          !childChildCategory &&
          !isMobile ? (
            <div className="ShopByCategory__banner">
              <img
                src={shopFilterData?.categories[0]?.image?.mediaItemUrl}
                alt="img"
              />
            </div>
          ) : null}
          <BreadCrumb path={path} shopType="brands" />
         
        </>
      )}
      {/* {(childChildCategory?.length || childChildChildCategory) && ( */}
      <Layout
        className={cs(
          "ShopByBrand",
          noProductsAvailable && "ShopLayout--no-products"
        )}
        head={
          <HandleLoadingState
            loading={brandLoading}
            loadingPlaceholder={<ShopLogoPlaceholder />}
          >
            <ApolloErrorGuard error={brandError}>
              {!brandLogo ? (
                <h1>{brandName}</h1>
              ) : (
                <Image style={{ width: 170 }} src={brandLogo} alt={brandName} />
              )}
            </ApolloErrorGuard>
          </HandleLoadingState>
        }
        sidebar={
          <ShopSidebar
            handleChange={(value: number[]) => handlePriceChange(value)}
            handleAfterChange={(value: number[]) =>
              handlePriceAfterChange(value)
            }
            priceState={priceState}
            className="ShopByBrand__sidebar"
          />
        }
        content={
          <ApolloErrorGuard error={error}>
            <ListOfProducts
              big
              loadingPlaceholderNumber={postsLimit}
              loading={loading}
              error={error?.message}
              products={products}
              isLoading={isLoadingMore}
              pageInfo={hasNextPage}
              onLoadMore={handleLoadMore}
            />
          </ApolloErrorGuard>
        }
      />
      {/* )} */}
    </ShopContextProvider>
  );
};
